
footer {
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
}

/* footer header styles */

footer > .footer_header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;

    border-bottom: solid 1px black;
}

footer > .footer_header > .email {
    display: flex;
    flex-direction: column;
}

footer > .footer_header > .email > span > a:last-child {
    color: black;
    text-decoration: none;
}

footer > .footer_header > div.email > span > a:hover {
    color: white;
    background-color: black;
}

footer > .footer_header > h2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
}

footer > .footer_header > .copyright {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

footer > .footer_header > .copyright > span:last-child {
    text-transform: uppercase;
}

footer > .footer_socials > .social > a:hover {
    color: white;
    background-color: black;
}

/* footer header media styles */

@media only screen and (max-width: 600px) {
    footer > .footer_header {
        flex-direction: column;
    }

    #root > footer > .footer_header > * {
        align-items: center;
        padding: 10px;
        width: 100%;
    }

    footer > .footer_header > h2 {
        order: 1;
    }

    footer > .footer_header > .email {
        order: 2;
    }

    footer > .footer_header > .copyright {
        order: 3;
    }
}

/* footer social buttons */

footer > .footer_socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0 10px 0;
}

footer > .footer_socials > .footer_socials__info {
    text-transform: uppercase;
    font-style: italic;
    padding: 10px;
}

footer > .footer_socials > .social > a {
    color: black;
    font-size: 23px;
    padding: 10px;
}
