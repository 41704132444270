/* menu toggling classes */
.show {
    display: block;
}

/* main parent styling */
header {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    background-color: black;
}

header > .title_bar,
header > nav {
    padding: 0 30px 0 30px;
}

/* title bar styles */

header > .title_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
}

header > .title_bar > h1 {
    margin: 0;
    text-align: center;
    line-height: 25px;
    font-size: 2em;
    font-weight: 400;
    text-transform: uppercase;
}

header > .title_bar > h1 > a {
    color: white;
    text-decoration: none;
}

header > .title_bar > .socials,
header > .title_bar > .description {
    display: flex;
    align-items: center;

    border-top: solid 1px white;
    border-bottom: solid 1px white;
}

header > .title_bar > .socials,
header > .title_bar > .description,
header > .title_bar > h1 {
    width: 33%;
    height: 50px;
}

header > .title_bar > .socials,
header > .title_bar > .description {
    font-size: 23px;
    font-weight: 400;
}

header > .title_bar > .socials a,
header > .title_bar > .socials > .mobileMenu {
    padding: 10px;
    color: white;
}

header > .title_bar > .socials > .social > a:hover {
    color: black;
    background-color: white;
}

header > .title_bar > .socials > .mobileMenu {
    display: none;
}

header > .title_bar > .socials {
    justify-content: space-between;
}

header > .title_bar > .description {
    justify-content: flex-end;
    font-style: italic;
    font-size: 18px;
}

/* title_bar media styles*/

@media only screen and (max-width: 600px) {

    header > .title_bar {
        flex-direction: column;
    }

    header > .title_bar > .socials,
    header > .title_bar > .description,
    header > .title_bar > h1 {
        width: 100%;
    }

    header > .title_bar > h1 {
        order: 1;
    }

    header > .title_bar > .description {
        order: 2;
        border: none;
        justify-content: center;
    }

    header > .title_bar > .socials {
        order: 3;
    }

    header > .title_bar > .socials > .mobileMenu {
        display: block;
    }

    
}

/* nav styles */

header > nav > ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
}

header > nav > ul > li {
    list-style: none;
}

header > nav > ul > li > a:visited,
header > nav > ul > li > a:link {
    color: white;
}

header > nav > ul > li > a {
    text-decoration: none;
}

.active {
    border-bottom: 1px solid white;
}

/* mobile nav styles */

@media only screen and (max-width: 600px){

    header > nav {
        display: none;
    }

    header > nav > ul {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    header > nav > ul > li {
        padding: 20px 0 20px 0;
    }
}