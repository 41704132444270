h1.admin__header {
    text-align: center;
}

button {
    align-self: center;
}

section.adminTools {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

h2.adminTool__header {
    text-align: center;
}

section.adminTool {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 20px 20px 20px;
}

form#adminFormAdd {
    display: flex;
    flex-direction: column;
    
    max-width: 300px;
}

form#adminFormAdd > input {
    margin: 10px 0;
    height: 30px;
    border: none;
}

form.adminFormEdit {
    display: flex;
    flex-direction: row;
}

/* Edit Form */

form.adminFormEdit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

}

div.editPhoto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

div.editPhoto > img {
    width: 300px;
    height: auto;
    padding: 10px 0;
}

div.editPhoto__description > button:nth-last-child(1) {
    margin: 10px;
}

div.editModal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

div.editModal__content {
    background-color: #fefefe;
    color: black;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

form.editModal__content-form {
    display: flex;
    flex-direction: column;
}