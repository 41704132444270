.availability,
.for_photographers,
.courses {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    margin: 20px 0;
}

img.serviceImg {
    height: auto;
}

.availability > *,
.for_photographers > *,
.courses > * {
    width: 600px;
}

.service_description {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service_description > p,
.service_description > ul {
    align-self: center;
    align-items: center;
}

.availability_header {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    text-align: center;
    margin: 20px 0;
    border-bottom: solid white 1px;
}

.lists {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.photography_courses {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    text-align: center;
    align-self: center;
    margin: 20px 0;
    border-bottom: solid white 1px;
}

@media screen and (max-width: 859px) {
    .availability > img,
    .courses > img {
        order: 1;
    }
}