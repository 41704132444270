
.photosComponent__header {
    font-weight: 400;
    text-align: center;
}

.photosComponent__images {
    margin-bottom: 30px;
    width: 100%;
    max-width: 1100px;
    height: auto;
}
