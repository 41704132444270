#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.homeComponent__quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: italic;
}

/* carousel styles */
.slider-container {
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
}

.slick-dots li button::before {
    color: white !important;
}

.slick-slide {
    width: 300px;
    position: relative;
}

.slider__container-img {
    max-height: 300px;
    margin: auto;
    height: auto;
}

/* Biography styles */
.homeComponent__bio {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.homeComponent__bio-image {
    align-self: flex-start;
    padding: 20px;
    width: auto;
    height: 300px;
}

.homeComponent__bio-description {
    width: auto;
    max-width: 500px;
    border-left: solid 1px white;
}

.homeComponent__bio-description > h2 {
    font-weight: 400;
}

.homeComponent__bio-description > p {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    
}

.homeComponent__bio-description > a {
    margin-left: 10px;
}

.homeComponent__recentWork {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeComponent__recentWork-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    /* height: 500px; */
}

.homeComponent__recentWork-header {
    font-weight: 500;
    font-style: italic;
}

.homeComponent__recentWork-images > img {
    padding: 20px 10px;
    max-height: 400px;
    min-height: 400px;
    height: auto;
    width: 300px;
    object-fit: cover;
    overflow: hidden;
}

@media screen and (max-width: 600px){
    
    .homeComponent__bio-image {
        height: 250px;
        padding: 10px;
    }
}

/* media screen fix for ios support */
@media screen and (max-width: 380px){

    .homeComponent__recentWork-images {
        /* flex-direction: column; */
    }

    .homeComponent__recentWork-images > img {
        width: 250px;
    }
}
