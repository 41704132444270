section.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;

}

h1.login__header {
    font-weight: 400;
}

form.login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

div.login__form--formGroup {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
    padding: 5px;
}

div.login__form--formGroup > input {
    height: 30px;
    border: none;
}