@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap');

/* default styles */
:Root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: black;
  color: white;

  font-family: 'Old Standard TT', serif;
  font-weight: 400;
}

button,
input[type='submit'] {
  margin: 10px 0;
  width: 100px;
  height: 25px;
  color: black;
  background-color: white;
  border: none;
  font-family: 'Old Standard TT', serif;
}

button:hover,
input[type='submit']:hover {
  cursor: pointer;
}


#root {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

/* basic link styling */
a:link,
a:visited,
a:hover,
a:active {
  color: white;
}

/* remove apple styling */
input,
button {
  -webkit-appearance: none;
}

header {
  z-index: 1;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 30px;
  margin-top: 129px;
  height: 100%;
}

@media screen and (max-width: 600px){
  main {
    margin-top: 177px;
  }
}

footer {
  flex-shrink: 0;
  padding: 0 30px 0 30px;
}