.pricing__header {
    font-weight: 400;
    text-align: center;
}

/* pricing intro section styles */
.pricing__intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pricing__intro > p {
    font-style: italic;
    max-width: 400px;
}

.pricing__intro-section {
    display: flex;  
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.pricing__intro-section:nth-child(odd) {
    background-color: #D4D4D4;
    color: black;
    flex-direction: row-reverse;
}

.pricing__intro-section:nth-child(even) {
    background-color: #3A6E6D;
}

.pricing__intro-section > img {
    height: auto;
    max-width: 400px;
    width: 100%;
    padding: 20px
}

.pricing__intro-section > div {
    max-width: 400px;
    width: 100%;
    padding: 20px;
}

@media screen and (max-width: 930px){
    .pricing__intro-section {
        justify-content: center;
    }
}

@media screen and (max-width: 462px) {
    .pricing__intro-section > img {
        padding: 0;
    }
}

/* wedding section styles */

.pricing__weddings,
.pricing__intimate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.pricing__weddings-prices,
.pricing__intimate-prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
}

.pricing__weddings > h2,
.pricing__intimate > h2 {
    text-align: center;
}

.pricing__weddings-price,
.pricing__intimate-price {
    width: 100%;
    max-width: 260px;
    padding: 10px;
}

.pricing__prints-price {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.pricing__prints-availableopt {
    width: 100%;
    max-width: 150px;
    padding: 10px;
    text-align: center;
    align-self: center;
    margin: 20px 0;
    border-bottom: solid white 1px;
}

.pricing__weddings-price > h3,
.pricing__intimate-price > h3 {
    text-align: center;
    border-bottom: solid white 1px;
}

.pricing__intimate-startingPrice {
    font-weight: 700;
    text-align: center;
}

@media screen and (max-width: 313px){
    .pricing__weddings-price,
    .pricing__intimate-price {
        max-width: 216px;
    }    
}