@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap);
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: black;
  color: white;

  font-family: 'Old Standard TT', serif;
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* default styles */
:Root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: black;
  color: white;

  font-family: 'Old Standard TT', serif;
  font-weight: 400;
}

button,
input[type='submit'] {
  margin: 10px 0;
  width: 100px;
  height: 25px;
  color: black;
  background-color: white;
  border: none;
  font-family: 'Old Standard TT', serif;
}

button:hover,
input[type='submit']:hover {
  cursor: pointer;
}


#root {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
}

/* basic link styling */
a:link,
a:visited,
a:hover,
a:active {
  color: white;
}

/* remove apple styling */
input,
button {
  -webkit-appearance: none;
}

header {
  z-index: 1;
}

main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0 30px;
  margin-top: 129px;
  height: 100%;
}

@media screen and (max-width: 600px){
  main {
    margin-top: 177px;
  }
}

footer {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding: 0 30px 0 30px;
}
/* menu toggling classes */
.show {
    display: block;
}

/* main parent styling */
header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
    width: 100%;
    background-color: black;
}

header > .title_bar,
header > nav {
    padding: 0 30px 0 30px;
}

/* title bar styles */

header > .title_bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 25px;
}

header > .title_bar > h1 {
    margin: 0;
    text-align: center;
    line-height: 25px;
    font-size: 2em;
    font-weight: 400;
    text-transform: uppercase;
}

header > .title_bar > h1 > a {
    color: white;
    text-decoration: none;
}

header > .title_bar > .socials,
header > .title_bar > .description {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    border-top: solid 1px white;
    border-bottom: solid 1px white;
}

header > .title_bar > .socials,
header > .title_bar > .description,
header > .title_bar > h1 {
    width: 33%;
    height: 50px;
}

header > .title_bar > .socials,
header > .title_bar > .description {
    font-size: 23px;
    font-weight: 400;
}

header > .title_bar > .socials a,
header > .title_bar > .socials > .mobileMenu {
    padding: 10px;
    color: white;
}

header > .title_bar > .socials > .social > a:hover {
    color: black;
    background-color: white;
}

header > .title_bar > .socials > .mobileMenu {
    display: none;
}

header > .title_bar > .socials {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

header > .title_bar > .description {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    font-style: italic;
    font-size: 18px;
}

/* title_bar media styles*/

@media only screen and (max-width: 600px) {

    header > .title_bar {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    header > .title_bar > .socials,
    header > .title_bar > .description,
    header > .title_bar > h1 {
        width: 100%;
    }

    header > .title_bar > h1 {
        -webkit-order: 1;
                order: 1;
    }

    header > .title_bar > .description {
        -webkit-order: 2;
                order: 2;
        border: none;
        -webkit-justify-content: center;
                justify-content: center;
    }

    header > .title_bar > .socials {
        -webkit-order: 3;
                order: 3;
    }

    header > .title_bar > .socials > .mobileMenu {
        display: block;
    }

    
}

/* nav styles */

header > nav > ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 0;
}

header > nav > ul > li {
    list-style: none;
}

header > nav > ul > li > a:visited,
header > nav > ul > li > a:link {
    color: white;
}

header > nav > ul > li > a {
    text-decoration: none;
}

.active {
    border-bottom: 1px solid white;
}

/* mobile nav styles */

@media only screen and (max-width: 600px){

    header > nav {
        display: none;
    }

    header > nav > ul {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        padding: 0;
    }

    header > nav > ul > li {
        padding: 20px 0 20px 0;
    }
}
#home {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.homeComponent__quote {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    font-style: italic;
}

/* carousel styles */
.slider-container {
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
}

.slick-dots li button::before {
    color: white !important;
}

.slick-slide {
    width: 300px;
    position: relative;
}

.slider__container-img {
    max-height: 300px;
    margin: auto;
    height: auto;
}

/* Biography styles */
.homeComponent__bio {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.homeComponent__bio-image {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    padding: 20px;
    width: auto;
    height: 300px;
}

.homeComponent__bio-description {
    width: auto;
    max-width: 500px;
    border-left: solid 1px white;
}

.homeComponent__bio-description > h2 {
    font-weight: 400;
}

.homeComponent__bio-description > p {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 10px;
    
}

.homeComponent__bio-description > a {
    margin-left: 10px;
}

.homeComponent__recentWork {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.homeComponent__recentWork-images {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    /* height: 500px; */
}

.homeComponent__recentWork-header {
    font-weight: 500;
    font-style: italic;
}

.homeComponent__recentWork-images > img {
    padding: 20px 10px;
    max-height: 400px;
    min-height: 400px;
    height: auto;
    width: 300px;
    object-fit: cover;
    overflow: hidden;
}

@media screen and (max-width: 600px){
    
    .homeComponent__bio-image {
        height: 250px;
        padding: 10px;
    }
}

/* media screen fix for ios support */
@media screen and (max-width: 380px){

    .homeComponent__recentWork-images {
        /* flex-direction: column; */
    }

    .homeComponent__recentWork-images > img {
        width: 250px;
    }
}


.contactComponent__contactInfo-info > .social {
    margin-top: 10px;
    font-size: 23px;
}

.social > a {
    padding: 10px;
}

.social > a:hover,
.contactComponent__contactInfo-info > a:hover {
    color: black;
    background-color: white;
}

h1.contactComponent__header {
    font-weight: 400;
    text-align: center;
}

.contactComponent__contactInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
    padding-bottom: 20px;
}

.contactComponent__contactInfo > img {
    height: auto;
    max-width: 400px;
    width: 100%;
}


.contactComponent__contactInfo-info {
    padding: 0 20px;
}

@media screen and (max-width: 697px){
    .contactComponent__contactInfo > img {
        padding: 10px 0;
    }

    .contactComponent__contactInfo-info {
        padding-left: 0;
    }
}

.contactComponent__contactInfo-info > h1 {
    margin-bottom: 0;
}

.contactComponent__contactInfo-info > p {
    margin-top: 0;
}

.contactComponent__contactInfo-info > h1,
.contactComponent__contactInfo-info > p {
    font-style: italic;
    font-weight: 400;
}

.contactComponent__form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; 
    max-width: 700px;
    width: 100%;
    margin-bottom: 30px;
}

.contactComponent__form--formInputs {
    width: 100%;
    background-color: #888787;
    color: black;
}

.contactComponent__form--formGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.contactComponent__form--formGroup > input {
    height: 30px;
    border: none;
}

.contactComponent__form--formGroup > label {
    padding: 10px 0;
}

.contactComponent__form--formGroup > textarea {
    height: 100px;
}

.contactComponent__form--formGroup > input:not(input[type='submit']),
.contactComponent__form--formGroup > label,
.contactComponent__form--formGroup > textarea {
    margin: 0 20px;
}

.contactComponent__form--formGroup > input[type='submit'] {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    margin: 20px 20px;
}

.alert {
    /* align-self: center; */
    padding: 20px;
    font-weight: 800;
    font-size: 20px;
    margin: 15px;
    height: auto;
    max-width: 300px;
}

.error {
    background-color: #f78383; /* Red */
    color: #610707;
}

.success {
    background-color: #7afa78; /* Red */
    color: #012e00;
}
/* faq header styles */
.faq__header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    padding: 30px 0;
}

.faq__header > h1 {
    width: 400px;
    text-transform: none;
    font-weight: 400;
}

.faq__header > p {
    font-style: italic;
    max-width: 400px;
}

/* faq card styles */
.faq__cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;

    padding: 10px;
}

.faq__cards-card {
    background-color: #3A6E6D;
    width: 100%;
    padding: 20px;
    margin: 20px;
}

@media screen and (min-width: 400px) {
    .faq__cards-card {
        width: 500px;
    }
}

/* footer quote */
.faq__footerQuote {
    max-width: 400px;
    padding: 40px 0;
    -webkit-align-self: center;
            align-self: center;
    font-style: italic;
}

@media screen and (max-width: 400px) {
    .faq__footerQuote,
    .faq__header > h1 {
        text-align: center;
    }
}
.pricing__header {
    font-weight: 400;
    text-align: center;
}

/* pricing intro section styles */
.pricing__intro {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.pricing__intro > p {
    font-style: italic;
    max-width: 400px;
}

.pricing__intro-section {
    display: -webkit-flex;
    display: flex;  
    -webkit-flex-wrap: wrap;  
            flex-wrap: wrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.pricing__intro-section:nth-child(odd) {
    background-color: #D4D4D4;
    color: black;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.pricing__intro-section:nth-child(even) {
    background-color: #3A6E6D;
}

.pricing__intro-section > img {
    height: auto;
    max-width: 400px;
    width: 100%;
    padding: 20px
}

.pricing__intro-section > div {
    max-width: 400px;
    width: 100%;
    padding: 20px;
}

@media screen and (max-width: 930px){
    .pricing__intro-section {
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media screen and (max-width: 462px) {
    .pricing__intro-section > img {
        padding: 0;
    }
}

/* wedding section styles */

.pricing__weddings,
.pricing__intimate {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 20px 0;
}

.pricing__weddings-prices,
.pricing__intimate-prices {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
}

.pricing__weddings > h2,
.pricing__intimate > h2 {
    text-align: center;
}

.pricing__weddings-price,
.pricing__intimate-price {
    width: 100%;
    max-width: 260px;
    padding: 10px;
}

.pricing__prints-price {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    text-align: start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px 0;
}

.pricing__prints-availableopt {
    width: 100%;
    max-width: 150px;
    padding: 10px;
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin: 20px 0;
    border-bottom: solid white 1px;
}

.pricing__weddings-price > h3,
.pricing__intimate-price > h3 {
    text-align: center;
    border-bottom: solid white 1px;
}

.pricing__intimate-startingPrice {
    font-weight: 700;
    text-align: center;
}

@media screen and (max-width: 313px){
    .pricing__weddings-price,
    .pricing__intimate-price {
        max-width: 216px;
    }    
}

footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: black;
    background-color: white;
}

/* footer header styles */

footer > .footer_header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px 0 0 0;

    border-bottom: solid 1px black;
}

footer > .footer_header > .email {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

footer > .footer_header > .email > span > a:last-child {
    color: black;
    text-decoration: none;
}

footer > .footer_header > div.email > span > a:hover {
    color: white;
    background-color: black;
}

footer > .footer_header > h2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
}

footer > .footer_header > .copyright {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

footer > .footer_header > .copyright > span:last-child {
    text-transform: uppercase;
}

footer > .footer_socials > .social > a:hover {
    color: white;
    background-color: black;
}

/* footer header media styles */

@media only screen and (max-width: 600px) {
    footer > .footer_header {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    #root > footer > .footer_header > * {
        -webkit-align-items: center;
                align-items: center;
        padding: 10px;
        width: 100%;
    }

    footer > .footer_header > h2 {
        -webkit-order: 1;
                order: 1;
    }

    footer > .footer_header > .email {
        -webkit-order: 2;
                order: 2;
    }

    footer > .footer_header > .copyright {
        -webkit-order: 3;
                order: 3;
    }
}

/* footer social buttons */

footer > .footer_socials {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px 0 10px 0;
}

footer > .footer_socials > .footer_socials__info {
    text-transform: uppercase;
    font-style: italic;
    padding: 10px;
}

footer > .footer_socials > .social > a {
    color: black;
    font-size: 23px;
    padding: 10px;
}


.photosComponent__header {
    font-weight: 400;
    text-align: center;
}

.photosComponent__images {
    margin-bottom: 30px;
    width: 100%;
    max-width: 1100px;
    height: auto;
}

section.login {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 50vh;

}

h1.login__header {
    font-weight: 400;
}

form.login__form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

div.login__form--formGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 350px;
    width: 100%;
    padding: 5px;
}

div.login__form--formGroup > input {
    height: 30px;
    border: none;
}
h1.admin__header {
    text-align: center;
}

button {
    -webkit-align-self: center;
            align-self: center;
}

section.adminTools {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 20px 0;
}

h2.adminTool__header {
    text-align: center;
}

section.adminTool {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    padding: 0 20px 20px 20px;
}

form#adminFormAdd {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    
    max-width: 300px;
}

form#adminFormAdd > input {
    margin: 10px 0;
    height: 30px;
    border: none;
}

form.adminFormEdit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

/* Edit Form */

form.adminFormEdit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;

}

div.editPhoto {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

div.editPhoto > img {
    width: 300px;
    height: auto;
    padding: 10px 0;
}

div.editPhoto__description > button:nth-last-child(1) {
    margin: 10px;
}

div.editModal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

div.editModal__content {
    background-color: #fefefe;
    color: black;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

form.editModal__content-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.availability,
.for_photographers,
.courses {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    margin: 20px 0;
}

img.serviceImg {
    height: auto;
}

.availability > *,
.for_photographers > *,
.courses > * {
    width: 600px;
}

.service_description {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.service_description > p,
.service_description > ul {
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
}

.availability_header {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    text-align: center;
    margin: 20px 0;
    border-bottom: solid white 1px;
}

.lists {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px 0;
}

.photography_courses {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin: 20px 0;
    border-bottom: solid white 1px;
}

@media screen and (max-width: 859px) {
    .availability > img,
    .courses > img {
        -webkit-order: 1;
                order: 1;
    }
}
