/* faq header styles */
.faq__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 30px 0;
}

.faq__header > h1 {
    width: 400px;
    text-transform: none;
    font-weight: 400;
}

.faq__header > p {
    font-style: italic;
    max-width: 400px;
}

/* faq card styles */
.faq__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    padding: 10px;
}

.faq__cards-card {
    background-color: #3A6E6D;
    width: 100%;
    padding: 20px;
    margin: 20px;
}

@media screen and (min-width: 400px) {
    .faq__cards-card {
        width: 500px;
    }
}

/* footer quote */
.faq__footerQuote {
    max-width: 400px;
    padding: 40px 0;
    align-self: center;
    font-style: italic;
}

@media screen and (max-width: 400px) {
    .faq__footerQuote,
    .faq__header > h1 {
        text-align: center;
    }
}