
.contactComponent__contactInfo-info > .social {
    margin-top: 10px;
    font-size: 23px;
}

.social > a {
    padding: 10px;
}

.social > a:hover,
.contactComponent__contactInfo-info > a:hover {
    color: black;
    background-color: white;
}

h1.contactComponent__header {
    font-weight: 400;
    text-align: center;
}

.contactComponent__contactInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 20px;
}

.contactComponent__contactInfo > img {
    height: auto;
    max-width: 400px;
    width: 100%;
}


.contactComponent__contactInfo-info {
    padding: 0 20px;
}

@media screen and (max-width: 697px){
    .contactComponent__contactInfo > img {
        padding: 10px 0;
    }

    .contactComponent__contactInfo-info {
        padding-left: 0;
    }
}

.contactComponent__contactInfo-info > h1 {
    margin-bottom: 0;
}

.contactComponent__contactInfo-info > p {
    margin-top: 0;
}

.contactComponent__contactInfo-info > h1,
.contactComponent__contactInfo-info > p {
    font-style: italic;
    font-weight: 400;
}

.contactComponent__form {
    display: flex;
    flex-direction: column;
    align-items: center; 
    max-width: 700px;
    width: 100%;
    margin-bottom: 30px;
}

.contactComponent__form--formInputs {
    width: 100%;
    background-color: #888787;
    color: black;
}

.contactComponent__form--formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contactComponent__form--formGroup > input {
    height: 30px;
    border: none;
}

.contactComponent__form--formGroup > label {
    padding: 10px 0;
}

.contactComponent__form--formGroup > textarea {
    height: 100px;
}

.contactComponent__form--formGroup > input:not(input[type='submit']),
.contactComponent__form--formGroup > label,
.contactComponent__form--formGroup > textarea {
    margin: 0 20px;
}

.contactComponent__form--formGroup > input[type='submit'] {
    align-self: flex-start;
    margin: 20px 20px;
}

.alert {
    /* align-self: center; */
    padding: 20px;
    font-weight: 800;
    font-size: 20px;
    margin: 15px;
    height: auto;
    max-width: 300px;
}

.error {
    background-color: #f78383; /* Red */
    color: #610707;
}

.success {
    background-color: #7afa78; /* Red */
    color: #012e00;
}